import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/bps/bps/node_modules/.pnpm/next@15.0.2_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-603e6108-20241029_shmlsksgbc27raajzjlet76yxi/node_modules/next/dist/lib/metadata/metadata-boundary.js");
